import React, { useState, useEffect } from 'react'
import { ethers } from 'ethers'
import { keccak256 } from 'ethers/lib/utils';
import BTGG from './contracts/artifacts/BTGG.json';

import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from "styled-components";
import { initWeb3Onboard } from './services'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import {
  useAccountCenter,
  useConnectWallet,
  useNotifications,
  useSetChain,
  useWallets,
  useSetLocale
} from '@web3-onboard/react'
import './App.css'
import { borderLeftColor } from '@mui/system';

const scriptFile = document.createElement("script");
scriptFile.src = "./timeline.js";//your js file path
scriptFile.async = true;
document.body.appendChild(scriptFile);

let provider

const NFTcontractAddress = "0x7151537cf5f16bec6a8c20919ff8aaf38cb08e53";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: '15px', borderRadius: "30px", background: "#0073ff3b" }} variant="determinate" {...props} />
      </Box>

    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};



function MintPage() {
  const [{ wallet }, connect, disconnect, updateBalances, setWalletModules] =
    useConnectWallet()
  const [notifications, customNotification, updateNotify] = useNotifications()
  const connectedWallets = useWallets()
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [web3Onboard, setWeb3Onboard] = useState(null)

  const [error, setError] = useState('');
  const [errCode, setErrCode] = useState(0);
  const [data, setData] = useState({});
  const [WL, setWL] = useState(false);
  const [VIP, setVIP] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [accountCenterPosition, setAccountCenterPosition] = useState('topRight')
  const [notifyPosition, setNotifyPosition] = useState('bottomRight')
  const [locale, setLocale] = useState('en')
  const [accountCenterSize, setAccountCenterSize] = useState('normal')
  const [accountCenterExpanded, setAccountCenterExpanded] = useState(false)

  const [progress, setProgress] = React.useState(10);


  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard);
  }, [])

  useEffect(() => {
    console.log(notifications)
  }, [notifications])

  useEffect(() => {
    if (!connectedWallets.length) return

    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )


  }, [connectedWallets, wallet])



  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0], disableModals: true })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])


  useEffect(() => {
    fetchData();
  }, []);






  async function fetchData() {
    if (typeof window.ethereum !== 'undefined') {

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(NFTcontractAddress, BTGG.abi, provider);

      try {
        let cost;

        const totalSupply = await contract.totalSupply();
        const step = await contract.sellingStep();
        if (step == 1) {
          cost = await contract.publicSalePrice();
        }
        const object = { "cost": String(cost), "totalSupply": String(totalSupply), "step": String(step) }

        setData(object);

      }
      catch (err) {
        setError(err.message);
      }
    }
  }


  async function mint() {
    if (typeof window.ethereum !== 'undefined') {
      let accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(NFTcontractAddress, BTGG.abi, signer);
      try {
        let overrides = {
          from: accounts[0],
          value: String(data.cost * quantity),
        }
        const transaction = await contract.publicSaleMint(accounts[0], quantity, overrides);
        await transaction.wait();
        fetchData();
      }
      catch (err) {
        setError(err.message);
        handleError(err.message);
      }
    }
  }
  async function handleError(err) {

    if (err.includes("user rejected transaction")) {
      console.log('User denied the transaction signature.');
      setErrCode(1);
      handleShow();
    }
    if (err.includes("insufficient funds")) {
      console.log('Insufficient funds');
      setErrCode(3);
      handleShow();
    }

  }

  function valueUp() {
    if (quantity < 5) {
      setQuantity(quantity + 1);
    }
  }
  function valueDown() {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  }
  function runOnStart() {
    var loadscr = document.getElementById("loadscr");
    loadscr.style.animation = "moveout 1.5s ease-in-out forwards";
  }
  if (document.readyState !== "loading") {
    runOnStart();
  }
  else {
    document.addEventListener('DOMContentLoaded', function () {
      runOnStart()
    });
  }
  return (
    <main>
      <div className="loadscr" id="loadscr">
        <img src="logofull.png" alt="" />
      </div>
      <section className="main">
        <nav>
          <a href="https://twitter.com/btggclub"><img src="twitter.svg" alt="" /></a>
          <a href="https://opensea.io/collection/bigtiddygothgirls"><img src="opensea.svg" alt="" /></a>
        </nav>
        <div className="topsection">
          <img src="logofull.png" className='logofull' alt="" />
          <div className="shadow"></div>
          <div className="main-content">

            <div className="containerr onboard">
              <div>
                {!wallet && (
                  <button
                    className="mintbutton"
                    onClick={() => {
                      connect()
                    }}
                  >
                    CONNECT
                  </button>
                )}
                {wallet && (
                  <div className="minting">
                    {data.step != 0 && data.step != null ? (<>
                      <div className="cost">
                        <h2>Price</h2>
                        <h3>{data.cost / 10 ** 18} <span>ETH</span></h3>
                      </div>

                      <div className='progress'>
                        <h3 className="minted">Total minted &nbsp;({data.totalSupply} / 6666) - {Math.round((data.totalSupply * 100 / 6666)) + '%'}</h3>
                        <Box sx={{ width: '100%', height: '60px' }}>
                          <LinearProgressWithLabel value={(data.totalSupply * 100 / 6666)} />
                        </Box>
                      </div>
                    </>
                    ) : (
                      <div>
                        <h3>Sale has not started yet.</h3>
                      </div>
                    )
                    }
                    {data.step == 1 && <div>
                      <div className='quantitymint'>
                        <h2>Quantity (MAX 5)</h2>
                        <input
                          type="number"
                          id="quantity"
                          min="1"
                          max="5"
                          step="1"
                          value={quantity}
                        />
                        <div className="quantitybuttons">
                          <div className="arrowup" onClick={valueUp}></div>
                          <div className="arrowdown" onClick={valueDown}></div>
                        </div>
                      </div>
                      <button className="mintbutton" onClick={mint}>MINT</button>
                    </div>}

                  </div>
                )}
              </div>

              {errCode == 1 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You rejected the transaction. Try minting again.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }
              {errCode == 2 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    You are trying to mint more than the allocated amount for your wallet during this sale.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }
              {errCode == 3 &&

                <Modal
                  show={show}
                  onHide={handleClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Not enough funds.
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>

                  </Modal.Footer>
                </Modal>

              }

            </div>


          </div>
          <h3 className='welcome-header'>Welcome to the darkest and sexiest spot <br />
            on the Blockchain</h3>
        </div>
        <div className="container">


          <div className="timeline">
            <div className="line"></div>

            <div className="section">
              <div className="bead"></div>
              <div className="content">
                <img src="/girl.png" loading="lazy" alt="" />
                <div className='col'>
                  <h4><b>Big Tiddy Goth Girls</b></h4>
                  <p>
                    6666 Big Tiddy Goth Girls ready
                    to takeover the Ethereum Blockchain.
                    A free mint for for the admirers of
                    beautiful goth girls and the excitement
                    they offer.
                  </p>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="bead"></div>
              <div className="content rev">
                <img src="/coffin.png" loading="lazy" alt="" />
                <div className='col'>
                  <h4><b>Coffin Juice</b></h4>
                  <p>
                    Carrying these huge tiddies is tiring,
                    luckily we have a source of energy
                    like no other. Coffin Juice is the #1
                    energy drink on the market. Whatever
                    they put in these cans just feels different.
                    Their new flavours are releasing soon
                    however they come with a warning...

                  </p>
                </div>
              </div>
            </div>

            <div className="section">
              <div className="bead"></div>
              <div className="content">
                <img src="/phase3.png" loading="lazy" alt="" />
                <div className='col'>
                  <h4><b>The Reapers Arms</b></h4>
                  <p>
                    The Reapers Arms is the most exclusive
                    club in town, they only grant access
                    to BTGG and our companions. Most
                    will never know what really goes on
                    behind its doors. The Reapers is also
                    the only place in the world with
                    Coffin Juice on tap.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="community">
          <h2>Community</h2>
          <p>
            BTGG is a community united by a love for tiddies and memes.
            We have created a fun space for people to share laughs and detach
            from the stresses of life. Our goal is to unite as many people as
            possible and create fun, engaging adventures along the way.
            Look forward to regular meme competitions, giveaways and much more.
          </p>
        </div>
        <nav>
          <a href="https://twitter.com/btggclub"><img src="twitter.svg" alt="" /></a>
          <a href="https://opensea.io/collection/bigtiddygothgirls"><img src="opensea.svg" alt="" /></a>
        </nav>
      </section>

    </main>
  )
}

export default MintPage
