import React from "react"
import { Routes, Route, HashRouter as Router, Link } from 'react-router-dom';
import "./App.css"
import MintPage from "./MintPage";


function App(){
    return (
        <Router>
            <div className="App">
         
                <Routes>
                    <Route path="/" element={<MintPage />} />
                   
                </Routes>
            </div>
        </Router>
    )
}
export default App